// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "bootstrap"
import Highcharts from 'highcharts';
require('highcharts/modules/debugger')(Highcharts);
require('highcharts/modules/accessibility')(Highcharts);
require("highcharts/modules/data")(Highcharts);
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);
require("highcharts/modules/offline-exporting")(Highcharts);
require("highcharts/modules/map")(Highcharts);

Rails.start()
ActiveStorage.start()



window.Highcharts = Highcharts;


window.remove_fields = function (link) {
  $(link).prev("input[type=hidden]").val("1");
  $(link).closest(".fields").hide();
}

window.add_fields = function(link, association, content) {
  var new_id = new Date().getTime();
  var regexp = new RegExp("new_" + association, "g")
  $(link).parent().before(content.replace(regexp, new_id));
}
